@font-face {
    font-family: Dunkin;
    src: url('../assets/fonts/dunkin/Dunkin.otf');
  }
  @font-face {
    font-family: valera-round;
    src: url('../assets/fonts/valera-round/VarelaRound-Regular.ttf');
  }
  h1, h2,h3,h4,h5, .dunkin {
      font-family: Dunkin;
  }
  .blue {
      color:#24406b;
  }
  .white {
    color:white;
  }
 
  .font-light {
      font-size: 1.3em;
  }
  input {
      border-radius: 19px !important;
  }
  label {
      font-family: valera-round;
  }
  .opacity {
    opacity:0.7;
  }
  .valera {
    font-family: valera-round;
}
input[type=radio] {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
input[type=checkbox] {
  width: 22px;
  height: 22px;
  padding-right: 10px;
}
.underline {
  text-decoration-line: underline;

}
.underline:hover {
  color:rgb(23, 194, 0);
}
.font-regular {
  font-size: 1.2em;
}


  .form-button {
    border-radius: 16px;
    border: 2px solid rgb(177, 177, 177);
    border-bottom: 6px solid rgb(177, 177, 177);
    margin: 10px;
    transition: all 0.1s;
    padding: 12px 16px;
    color: #5d5d5d;
    background-color: #FFFF !important;
    font-family: valera-round;
  }
  .form-button:hover {
    background-color: #ddf4ff;
    border-color: #24406b;
    color: #24406b;

  }
  .form-button:disabled {
    background-color: #ddf4ff;
    border-color: grey;
    color: grey;
  }
  .form-button:valid {
    border-radius: 16px;
    border: 2px solid rgb(177, 177, 177);
    border-bottom: 6px solid rgb(177, 177, 177);
    margin: 10px;
    transition: all 0.1s;
    padding: 12px 16px;
    color: #5d5d5d;
    background-color: #FFFF !important;
    font-family: valera-round;
  }
  .card-selector {
    background-color: #24406b;
    border-radius: 50px !important;

  }
  #dropdown-button {
    border-radius: 40px !important;
    border: 1px solid rgb(177, 177, 177);
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
    min-width:300px;
    background-color: #FFFF;
    color: black; 
    font-size: 1.2em !important;

    
  }
  .pricing-card {
    border-radius: 40px !important;
    border: 3px solid #24406b;
  }
  .pricing-button {
    border-radius: 40px !important;
    border: 2px solid #24406b;
    color: #24406b;
  }
  #radio-form {
    color:white;
    font-family: valera-round;
    font-size: 25px !important;
  }
  .card-selector-pricing {
    background-color: #24406b;
    border-radius: 20px !important;
    -webkit-box-shadow: -3px 4px 12px -8px #000000; 
    box-shadow: -3px 4px 12px -8px #000000;

  }
  .border-error {
    border: 2px solid red;
  }
  .border-form {
    border: 1px solid #24406b;
  }

  .arrow1 {
    animation: slide1 1s ease-in-out infinite;
    margin-left: 9px;
    position: relative;

  }
  @keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
  }
.form-labels {
  width: 300px;
  margin-right: 30px;

}
.flying-panda {
  -webkit-box-shadow: 5px 5px 15px -11px #000000; 
box-shadow: 5px 5px 15px -11px #000000;
}
.cat-type { 
  border: 1px solid rgba(128, 128, 128, 0.363); 
  width: 300px;
  margin-right: 30px;
  border-radius: 60px;
  margin-top: 5px;
}
.form-content {
  border: 1px solid #24406b;
  border-radius: 40px !important;
  padding: 20px;

}